.react-datepicker__input-container input {
  @apply block w-full border-b-2 text-md;
}

.react-datepicker-wrapper {
  @apply w-full;
}

.react-datepicker__tab-loop {
  @apply relative w-full;
}

.react-datepicker-popper {
  @apply z-40 w-full max-w-[375px] rounded border border-lineColor bg-white p-4 shadow-dropdown;
}

.react-datepicker {
  @apply font-semibold;
}

.react-datepicker-left {
  /* @apply absolute left-0 right-auto top-11 transform-none !important; */
}

.react-datepicker-right {
  /* @apply absolute right-0 left-auto top-11 transform-none !important; */
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 20;
}

.react-datepicker__month-container {
  /* @apply flex flex-col; */
}

.react-datepicker__month {
  /* @apply flex flex-col; */
}

.react-datepicker__current-month {
  /* @apply ml-2.5 text-lg font-semibold text-gray-800; */
}

.react-datepicker__week {
  @apply flex justify-around;
}

/* S M T 등 요일 이름 */
.react-datepicker__day-names {
  @apply mb-1 flex cursor-default justify-around text-iconColor;
}

.react-datepicker__day-name {
  @apply flex-center h-8 w-8;
}

.react-datepicker__navigation {
  /* @apply absolute top-2; */
}

/* 날짜 */
.react-datepicker__day {
  @apply mb-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded outline-none;
}

.react-datepicker__day--outside-month {
  @apply text-iconColor;
}

.react-datepicker__day--outside-month:hover {
  @apply bg-background;
}

.react-datepicker__day--today {
  @apply text-primary;
}

.react-datepicker__day.day__holiday:not(.react-datepicker__day--outside-month) {
  @apply text-error;
}

@media (hover: hover) {
  .react-datepicker__day:not([class$="--outside-month"], [class$="--selected"]):hover {
    @apply bg-[#e2ebff];
  }
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--selected:not(.rangepicker__day) {
  @apply h-8 w-8 bg-primary text-white shadow-md;
}

/*
---------------------------daterangepicker style---------------------------------
*/

.rangepicker__day {
  @apply relative;
}

@media (hover: hover) {
  .rangepicker__day:hover,
  .rangepicker__day.react-datepicker__day--keyboard-selected {
    @apply border-none;
  }
}

.rangepicker__day > .date {
  @apply z-[1] flex h-full w-full items-center justify-center rounded;
}

@media (hover: hover) {
  .rangepicker__day > .date:hover,
  .rangepicker__day.react-datepicker__day--keyboard-selected > .date {
    /*@apply border-2 border-[#3751FF33];*/
  }
}

.rangepicker__day.react-datepicker__day--in-selecting-range > .date,
.rangepicker__day.react-datepicker__day--in-range > .date {
  @apply text-[#28282980];
}

.rangepicker__day.react-datepicker__day--selected > .date,
.rangepicker__day.react-datepicker__day--selecting-range-start > .date,
.rangepicker__day.react-datepicker__day--range-start > .date,
.rangepicker__day.react-datepicker__day--range-end > .date {
  @apply bg-primary text-white shadow-md;
}

.rangepicker__day > .background {
  @apply absolute z-[0];
}

.rangepicker__day.react-datepicker__day--in-selecting-range > .background,
.rangepicker__day.react-datepicker__day--in-range > .background {
  @apply h-8 w-12 rounded bg-[#e2ebff];
}

/* 선택된 끝에 오른쪽 배경색 안줌.. */
.react-datepicker__day--range-end > .background {
  @apply right-0 w-8;
}

/* 선택된 시작에 대해서는 오른쪽 배경색을 줌. */
.react-datepicker__day--range-start > .background {
  @apply left-0 w-8;
}

/* 선택 중에 시작점에 배경색 안줌. */
.react-datepicker__day--selecting-range-start > .background {
  @apply bg-transparent !important;
}

@media (hover: hover) {
  /* 현재 선택중인것중에 가장 마지막 애는 오른쪽에 배경색 안줌. */
  .react-datepicker__day--in-selecting-range:hover > .background {
    @apply right-0 w-8;
  }
}

/* 시작 = 끝이면 백그라운드 없음. */
.react-datepicker__day--range-start.react-datepicker__day--range-end
  > .background {
  @apply bg-transparent;
}

/* 선택 중에 옆에가 선택의 시작이면 오른쪽에 배경색 길게 주기 */
.react-datepicker__day--selecting-range-start
  + .react-datepicker__day--in-selecting-range
  > .background {
  @apply w-12;
}

/* ======================== Year Picker ======================== */
.react-datepicker__year-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;
}

.react-datepicker__year-text {
  border-radius: 5px;
  border: 1px solid #999;
  color: #999;
  max-width: 90px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-weight: 500;
}

.react-datepicker__year-text:hover:not([class*="selected"]) {
  background: #f3f4fb;
  border-color: #f3f4fb;
}

.react-datepicker__year-text--selected {
  border: none;
  border-radius: 0.3rem;
  box-shadow: 0 5px 10px rgba(105, 119, 157, 0.25);
  background: #3751ff;
  color: white;
  font-weight: 600;
}

.react-datepicker__navigation {
  position: absolute;
  margin-top: 5px;
}

.react-datepicker__navigation--previous {
  left: 1.25rem;
}

.react-datepicker__navigation--next {
  right: 1.25rem;
}

.react-datepicker-year-header {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}
